<template>
  <v-row class="app-receipt-preview">
    <v-col
      cols="12"
      md="9"
    >
      <v-card class="app-receipt">
        <!-- Header -->
        <div class="py-5 px-5">
          <Letterhead :width="'100%'" />

          <div class="d-flex flex-wrap justify-space-between mt-9">
            <!-- Date -->
            <div class="d-flex align-center">
              <span class="me-2 font-weight-medium text--primary">Date Issued: {{ issueDate || dateToday }}</span>
            </div>

            <!-- Receipt number -->
            <div
              class="d-flex align-center justify-end"
              :class="{ 'mt-4': $vuetify.breakpoint.xsOnly }"
            >
              <v-select
                v-model="receipt.branch_id"
                :items="branchOptions"
                item-value="id"
                item-text="name"
                single-line
                outlined
                dense
                hide-details
                clearable
                placeholder="Select Branch"
                class="receipt-add-branch"
              ></v-select>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Invoice Meta -->
        <div class="py-5 px-5">
          <div class="mb-3 d-flex">
            <div class="px-3 flex-grow-1 font-weight-semibold">
              <v-row>
                <!-- Invoice Search -->
                <v-col
                  cols="12"
                  sm="6"
                  :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <p class="font-weight-semibold payment-details-header">
                    Invoice to Settle:
                  </p>
                  <v-autocomplete
                    ref="invoiceMetaList"
                    v-model="receipt.invoice_id"
                    :loading="searchLoading"
                    :items="invoiceMetas"
                    :search-input.sync="searchText"
                    cache-items
                    item-value="id"
                    item-text="description"
                    single-line
                    outlined
                    dense
                    hide-details
                    placeholder="Start Typing to Search"
                    class="search-container"
                  ></v-autocomplete>
                </v-col>

                <!-- Autofill -->
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex align-center"
                  :class="{ 'px-0': $vuetify.breakpoint.xsOnly }"
                >
                  <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
                  <v-btn
                    color="primary"
                    :loading="fillLoading"
                    :disabled="!receipt.invoice_id || fillLoading"
                    :class="{ 'autofill-button': $vuetify.breakpoint.smAndUp }"
                    @click="fillWithInvoice()"
                  >
                    Autofill
                  </v-btn>
                  <v-tooltip
                    :right="$vuetify.breakpoint.smAndUp"
                    :left="$vuetify.breakpoint.xsOnly"
                    :nudge-bottom="$vuetify.breakpoint.smAndUp ? 20 : 0"
                    :nudge-top="$vuetify.breakpoint.xsOnly ? 5 : 0"
                    max-width="250"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        size="30"
                        class="ml-3 hover-pointer"
                        :class="{ 'autofill-button': $vuetify.breakpoint.smAndUp }"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="25">
                          {{ icons.mdiAlertCircleOutline }}
                        </v-icon>
                      </v-avatar>
                    </template>
                    <h3 class="my-2">
                      {{ t('How to use Autofill') }}
                    </h3>
                    <p class="mb-1">
                      {{ t('You can search using') }}:
                    </p>
                    <ul class="mb-1">
                      <li>
                        {{ t('#Invoice No.') }}
                      </li>
                      <li>
                        {{ t('Car Registration No.') }}
                      </li>
                      <li>
                        {{ t('Customer Name') }}
                      </li>
                    </ul>
                    <p class="mb-2">
                      {{ t('Press the autofill button and wait for the form to be filled automatically!') }}
                    </p>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
            <div class="header-spacer"></div>
          </div>
        </div>

        <v-divider></v-divider>

        <v-expansion-panels class="pa-5">
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              1. Customer Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
            <!-- Customer Details -->
              <div class="mb-3 d-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <!-- Customer Type -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Customer Type:
                      </p>
                      <v-select
                        v-model="receipt.customer_type"
                        :items="customerTypeOptions"
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Select Customer Type"
                        @input="customerTypeChange(), netPaymentRef.validate()"
                      ></v-select>
                    </v-col>

                    <!-- Select Broker -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type === 'Non quoted/Broker'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Select Broker:
                      </p>
                      <v-select
                        v-model="receipt.broker_id"
                        :items="brokerOptions"
                        item-value="id"
                        item-text="name"
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Select Broker"
                        @input="brokerChange()"
                      >
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <v-list-item ripple :to="{ name: 'apps-profile-creation-broker-add'}">
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon>{{ icons.mdiPlus }}</v-icon>
                                Create New Broker
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>

                    <!-- Select Corporate -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type === 'Corporate'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Select Corporate:
                      </p>
                      <v-select
                        v-model="receipt.corporate_id"
                        :items="corporateOptions"
                        item-value="id"
                        item-text="name"
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Select Corporate"
                        @input="corporateChange()"
                      >
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <v-list-item ripple :to="{ name: 'apps-profile-creation-corporate-add'}">
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon>{{ icons.mdiPlus }}</v-icon>
                                Create New Corporate
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>

                    <!-- Select Niagamas Loyalty -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type === 'Niagamas loyalty'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Select Loyalty:
                      </p>
                      <v-select
                        v-model="receipt.niagamas_loyalty_id"
                        :items="loyaltyOptions"
                        item-value="id"
                        item-text="name"
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Select Loyalty Customer"
                        @input="loyaltyChange()"
                      >
                        <template v-slot:append-item>
                          <v-divider class="mb-2"></v-divider>
                          <v-list-item ripple :to="{ name: 'apps-profile-creation-loyalty-add'}">
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-icon>{{ icons.mdiPlus }}</v-icon>
                                Create New Loyalty Customer
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- Customer Name -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Receipt From:
                      </p>
                      <v-text-field
                        v-model="receipt.customer_name"
                        single-line
                        dense
                        outlined
                        hide-details
                        :placeholder="(receipt.customer_type === 'Non quoted/Broker' || receipt.customer_type === 'Corporate' || receipt.customer_type === 'Niagamas loyalty') ? 'Policy Holder Name' : 'Customer Name'"
                      ></v-text-field>
                    </v-col>

                    <!-- Customer Contact -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type !== 'Non quoted/Broker' && receipt.customer_type !== 'Corporate' && receipt.customer_type !== 'Niagamas loyalty'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Contact No.:
                      </p>
                      <v-text-field
                        v-model="receipt.customer_contact_number"
                        single-line
                        dense
                        outlined
                        type="number"
                        :rules="[v => /[0-9\-().\s]{7}$/.test(v) || 'Must be valid contact number']"
                        hide-details="auto"
                        :placeholder="'Customer Contact Number'"
                        prefix="+673"
                      ></v-text-field>
                    </v-col>

                    <!-- Customer IC/ID Number -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                      v-if="receipt.customer_type === 'Staff rate'"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        IC/ID Number:
                      </p>
                      <v-text-field
                        v-model="receipt.customer_ic_number"
                        single-line
                        dense
                        outlined
                        hide-details
                        :placeholder="'Customer IC/ID Number'"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              2. Insurance Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <!-- Receipt Details -->
              <div class="mb-3 d-none d-md-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <span>Description</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Amount</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Summable Amount</span>
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>

              <!-- Insurance Category -->
              <v-card
                outlined
                class="d-flex pa-3"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.insurance_category_id"
                      :items="insuranceCategoryOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Select Insurance Category"
                      @input="netPaymentRef.validate()"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance Product Type -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.insurance_product_id"
                      :items="insuranceProductOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Select Insurance Product Type"
                      :disabled="typeof receipt.invoice_id === 'number'"
                      @change="clearRoadTaxInfo()"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance Policy Number -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="receipt.insurance_policy_number"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Insurance Policy Number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Car Registration Number -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="receipt.car_registration_number"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Car Registration Number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.insurance_id"
                      :items="insuranceOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Select Principle"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Sum Insured -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center"
                  >
                    <p class="mb-0">
                      Sum Insured
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.sum_insured"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance Premium -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center"
                  >
                    <p class="mb-0">
                      Insurance Premium
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.insurance_premium"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <!-- After Discount -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <p class="mb-0">
                      After Discount
                    </p>
                    <p
                      v-if="receipt.payment_method_id == 5"
                      class="text-xs mb-0"
                    >
                      Kindly add 2.4% additional charges for card payments.
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    offset-sm="3"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.after_discount"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                      @input="netPaymentRef.validate()"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Net Payment -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    <p class="mb-0">
                      Net Payment
                      <span class="text-xs">(optional)</span>
                    </p>
                    <p class="text-xs mb-0">
                      For internal use only. This will not be printed or added to current total.
                      <br>
                      <span v-if="receipt.payment_method_id == 5">Kindly add 2.4% additional charges for card payments.</span>
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    offset-sm="1"
                    sm="3"
                  >
                    <v-text-field
                      ref="netPaymentRef"
                      v-model="receipt.net_payment"
                      :rules="netPaymentRules"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Insurance Policy -->
              <v-card
                v-if="receipt.insurance_category_id != 4"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-file-input
                      prepend-icon=""
                      :append-icon="icons.mdiPaperclip"
                      accept="application/pdf, image/png, image/jpg, image/jpeg"
                      show-size
                      dense
                      outlined
                      :rules="policyUploadRules"
                      label="Upload insurance policy"
                      hide-details="auto"
                      @change="directUploadPolicy($event)"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip
                          small
                          label
                          color="primary"
                        >
                          {{ text }}
                        </v-chip>
                      </template>

                      <template v-slot:append>
                        <v-progress-circular
                          v-if="uploadLoading"
                          color="primary"
                          indeterminate
                          size="25"
                        ></v-progress-circular>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card>

              <v-card
                v-if="receipt.insurance_category_id === 1 && receipt.insurance_product_id === 1"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-card-text class="pa-0">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <p>
                        Insurance expiry date:
                      </p>
                      <!-- Expiry Date-->
                      <v-menu
                        v-model="isExpiryDateMenuOpen"
                        :close-on-content-click="!isExpiryDateMenuOpen"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="expiryDateFormatted"
                            :append-icon="icons.mdiCalendar"
                            placeholder="Not set"
                            readonly
                            dense
                            outlined
                            hide-details
                            clearable
                            class="mt-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="isExpiryDateMenuOpen = true"
                            @click:clear="receipt.insurance_expired_at = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="receipt.insurance_expired_at"
                          color="primary"
                          type="month"
                          no-title
                          @click:month="isExpiryDateMenuOpen = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="isMotorInsurance">
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              3. Roadtax Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <!-- Road Tax Price -->
              <div class="mb-3 d-none d-md-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <span>Description</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Amount</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Summable Amount</span>
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>

              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center"
                  >
                    <p class="mb-0">
                      Road Tax Price
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    offset-sm="3"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.road_tax"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Require inspection -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.require_inspection"
                      :items="[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Does vehicle need inspection?"
                      @change="receipt.crw_number = null"
                    ></v-select>

                    <template>
                      <v-text-field
                        v-if="receipt.require_inspection"
                        v-model="receipt.crw_number"
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Enter CRW no."
                        class="mt-3"
                      ></v-text-field>
                    </template>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Require additional time to process -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.require_additional_process_time"
                      :items="[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Does roadtax require additional time to process?"
                      @change="updateProcessingDate($event)"
                    ></v-select>
                    <v-menu
                      v-if="receipt.require_additional_process_time"
                      v-model="isAdditionalDateMenuOpen"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :append-icon="icons.mdiCalendar"
                          :value="new Date(receipt.processing_date).toLocaleDateString()"
                          placeholder="'Enter processing date'"
                          readonly
                          dense
                          outlined
                          hide-details
                          class="mt-3"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="receipt.processing_date"
                        :min="new Date().toISOString().substring(0, 10)"
                        color="primary"
                        :first-day-of-week="1"
                        @input="isAdditionalDateMenuOpen = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Request for Express Service -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.express_charges"
                      :items="[{ id: '10', name: 'Yes' }, { id: '0', name: 'No' }]"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Request for Express Service?"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="receipt.express_charges === '10'"
                    cols="12"
                    offset-sm="3"
                    sm="3"
                    class="d-flex align-center"
                  >
                    <span>$10.00</span>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Request for Delivery Service -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.delivery_charges"
                      :items="[{ id: '10', name: 'Yes' }, { id: '0', name: 'No' }]"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Request for Delivery Service?"
                      @change="clearDeliveryAddress()"
                    ></v-select>
                    <template>
                      <v-textarea
                        v-if="receipt.delivery_charges === '10'"
                        v-model="receipt.remarks.address"
                        placeholder="Delivery Address"
                        dense
                        outlined
                        rows="3"
                        hide-details
                        class="mt-3"
                      ></v-textarea>
                    </template>
                  </v-col>
                  <v-col
                    v-if="receipt.delivery_charges === '10'"
                    cols="12"
                    offset-sm="3"
                    sm="3"
                    class="d-flex align-center"
                  >
                    <span>$10.00</span>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Pickup Location -->
              <v-card
                v-if="isMotorInsurance"
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-select
                      v-model="receipt.pickup_location_id"
                      :items="pickupLocationOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      placeholder="Select Road Tax Pickup Location"
                    ></v-select>

                    <template>
                      <v-text-field
                        v-if="isOtherPickupLocation"
                        v-model="receipt.remarks.pickup_location"
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Enter Road Tax Pickup Location"
                        class="mt-3"
                      ></v-text-field>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              {{ isMotorInsurance ? '4' : '3' }}. Additional Charges Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class=pa-5>
              <!-- Additional Fees -->
              <div class="mb-3 d-none d-md-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <span>Description</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Amount</span>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <span>Summable Amount</span>
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>

              <v-card
                outlined
                class="d-flex pa-3"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    class="d-flex align-center"
                  >
                    <p class="mb-0">
                      Additional Fees
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    offset-sm="3"
                    sm="3"
                  >
                    <v-text-field
                      v-model="receipt.additional_fees"
                      outlined
                      dense
                      min="0"
                      type="number"
                      hide-details="auto"
                      prefix="$"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="pt-0"
                  >
                    <v-select
                      v-model="receipt.additional_fees_info_id"
                      :items="additionalFeesInfoOptions"
                      item-value="id"
                      item-text="name"
                      dense
                      outlined
                      hide-details="auto"
                      clearable
                      placeholder="Select Additional Fees Info"
                    ></v-select>

                    <template>
                      <v-text-field
                        v-if="isOtherAdditionalFeesInfo"
                        v-model="receipt.remarks.additional_fees"
                        single-line
                        dense
                        outlined
                        hide-details="auto"
                        placeholder="Additional Fees Remarks"
                        class="mt-3"
                      ></v-text-field>
                    </template>
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              {{ isMotorInsurance ? '5' : '4' }}. Discount Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-card-text class="pa-0">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="voucherIssueAmount"
                        :items="voucherAmountOptions"
                        item-text="name"
                        item-value="id"
                        :hint="t('Select amount to issue a voucher to customer for future use')"
                        persistent-hint
                        dense
                        outlined
                        hide-details="auto"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Voucher -->
              <v-card
                outlined
                class="d-flex pa-3 mt-5"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      v-model="voucher.code"
                      outlined
                      dense
                      maxlength="4"
                      hint="This is the 4-digit code on the voucher coupon, e.g. 0051, 1147, 2089, etc."
                      persistent-hint
                      hide-details="auto"
                      placeholder="Voucher Code (optional)"
                      @input="voucher.code.length === 4 ? checkVoucher() : clearVoucherUse(), netPaymentRef.validate()"
                      @keypress="isNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="voucher.amount"
                    cols="12"
                    offset-sm="3"
                    sm="3"
                  >
                    <div class="mt-0 mt-sm-2">
                      $ {{ voucher.amount }}
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-5 font-weight-semibold">
              {{ isMotorInsurance ? '6' : '5' }}. Payment Information
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-5">
              <div class="mb-3 d-flex">
                <div class="px-3 flex-grow-1 font-weight-semibold">
                  <v-row>
                    <!-- Payment Method -->
                    <v-col
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Payment By:
                      </p>
                      <v-select
                        v-model="receipt.payment_method_id"
                        :items="paymentMethodOptions"
                        item-value="id"
                        item-text="name"
                        single-line
                        outlined
                        dense
                        hide-details
                        clearable
                        placeholder="Select Payment Method"
                        @change="clearPaymentDetailNumbers()"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Bank Payment Screenshot -->
                    <v-col
                      v-if="receipt.payment_method_id == 1 || receipt.payment_method_id == 2"
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Bank Payment Screenshot:
                      </p>
                      <v-file-input
                        prepend-icon=""
                        :append-icon="icons.mdiPaperclip"
                        accept="application/pdf, image/png, image/jpg, image/jpeg"
                        show-size
                        dense
                        outlined
                        :rules="paymentScreenshotRules"
                        placeholder="Upload payment screenshot"
                        hide-details="auto"
                        @change="directUploadScreenshot($event)"
                      >
                        <template v-slot:selection="{ text }">
                          <v-chip
                            small
                            label
                            color="primary"
                          >
                            {{ text }}
                          </v-chip>
                        </template>

                        <template v-slot:append>
                          <v-progress-circular
                            v-if="uploadScreenshotLoading"
                            color="primary"
                            indeterminate
                            size="25"
                          ></v-progress-circular>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>

                  <v-row>
                    <!-- Trace Number -->
                    <v-col
                      v-if="receipt.payment_method_id == 5"
                      cols="12"
                      sm="6"
                      :class="{ 'pl-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Trace Number:
                      </p>
                      <v-text-field
                        v-model="receipt.trace_number"
                        single-line
                        dense
                        outlined
                        hide-details
                        placeholder="Enter 5 digit Trace No."
                      ></v-text-field>
                    </v-col>

                    <!-- Card Number -->
                    <v-col
                      v-if="receipt.payment_method_id == 5"
                      cols="12"
                      sm="6"
                      :class="{ 'pr-0 mb-6 mb-sm-0': $vuetify.breakpoint.smAndUp, 'px-0': $vuetify.breakpoint.xsOnly }"
                    >
                      <p class="font-weight-semibold payment-details-header">
                        Card Number:
                      </p>
                      <v-text-field
                        v-model="receipt.partial_card_number"
                        single-line
                        dense
                        outlined
                        hide-details
                        placeholder="Enter Card No. last 4 digits"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="header-spacer"></div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-divider></v-divider>

        <div class="py-5 px-5">
          <!-- General remarks -->
          <v-card
            outlined
            class="d-flex pa-3 mt-5"
          >
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-textarea
                  v-model="receipt.remarks.general"
                  dense
                  outlined
                  rows="3"
                  hide-details="auto"
                  placeholder="General Remarks"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card>

        </div>

        <v-divider></v-divider>

        <!-- Receipt Footer -->
        <div class="py-5 px-5">
          <!-- Issuer -->
          <v-row>
            <v-col
              cols="12"
              sm="6"
              class="d-flex align-center"
            >
              <p class="mb-0 pr-2 font-weight-semibold">
                Issued By:
              </p>
              <p class="mb-0">
                {{ userData.nickname }}
              </p>
            </v-col>

            <v-col
              cols="4"
              sm="3"
              class="d-flex align-center justify-end"
            >
              <p class="mb-0 font-weight-semibold">
                Total:
              </p>
            </v-col>

            <!-- Total -->
            <v-col
              cols="8"
              sm="3"
              :class="{ 'px-0': $vuetify.breakpoint.xsOnly }"
            >
              <p class="mb-0 text-xl font-weight-semibold">
                ${{ calculatedTotal }}
              </p>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-card class="mb-6">
        <v-card-text>
          <v-btn
            color="primary"
            block
            :loading="loading"
            :disabled="loading"
            @click="createReceipt()"
          >
            {{ t('Confirm Receipt') }}
          </v-btn>

          <div class="text-center mt-4">
            {{ t('Please ensure that all fields are filled correctly as this record will not be editable after confirming.') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card
        v-if="errors.length != 0"
        class="mb-6"
      >
        <v-card-text>
          <Alert
            :messages="errors"
            color="error"
          ></Alert>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-text>
          <div class="text-center">
            Commission Reference
          </div>
          <div class="mt-4">
            <v-select
              v-model="commissionCustomerType"
              :items="customerTypeOptions"
              outlined
              dense
              hide-details
              placeholder="Select Customer Type"
            ></v-select>
          </div>
          <div>
            <v-simple-table class="text-center mt-4" v-if="commissionCustomerType">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">
                      Profit
                    </th>
                    <th class="text-center">
                      Commission
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in commissionReferenceData" :key="index">
                    <td>{{ data.range }}</td>
                    <td>{{ data.commission }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <div class="mt-4">
            Commission given only for New/Renewal insurance category.
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiAlertCircleOutline,
  mdiPaperclip,
  mdiCalendar,
  mdiPlus,
} from '@mdi/js'
import {
  inject,
  onUnmounted,
  onMounted,
  computed,
  ref,
  watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { Letterhead } from '@/components/apps/previews'
import Alert from '@/components/Alert'
import receiptStoreModule from './receiptStoreModule'
import invoiceStoreModule from '../invoice/invoiceStoreModule'

export default {
  components: {
    Letterhead,
    Alert,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')
    const { router } = useRouter()
    const RECEIPT_APP_STORE_MODULE_NAME = 'app-receipt'
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    if (!store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) {
      store.registerModule(RECEIPT_APP_STORE_MODULE_NAME, receiptStoreModule)
    }

    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    }

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const receipt = ref({
      branch_id: null,
      customer_name: null,
      customer_contact_number: null,
      customer_type: null,
      broker_id: null,
      corporate_id: null,
      niagamas_loyalty_id: null,
      customer_ic_number: null,
      roc_number: null,
      payment_method_id: null,
      trace_number: null,
      partial_card_number: null,
      insurance_category_id: null,
      insurance_product_id: null,
      insurance_policy_number: null,
      car_registration_number: null,
      insurance_id: null,
      pickup_location_id: null,
      additional_fees_info_id: null,
      require_inspection: null,
      crw_number: null,
      sum_insured: null,
      insurance_premium: null,
      after_discount: null,
      voucher_code: null,
      voucher_amount: null,
      road_tax: null,
      additional_fees: null,
      net_payment: null,
      full_spd_number: null,
      insurance_policy: null,
      payment_screenshot: null,
      require_additional_process_time: null,
      processing_date: null,
      express_charges: null,
      delivery_charges: null,
      remarks: {
        general: null,
        pickup_location: null,
        additional_fees: null,
        address: null,
      },
      invoice_id: null,
      insurance_expired_at: `${Number(yearNow) + 1}-${monthNow}-${dayNow}`,
    })
    const errors = ref([])
    const userData = JSON.parse(localStorage.getItem('userData'))
    const dateToday = new Date().toLocaleDateString('en-SG')
    const invoiceMetas = ref([])
    const paymentMethodOptions = ref([])
    const insuranceCategoryOptions = ref([])
    const insuranceProductOptions = ref([])
    const insuranceOptions = ref([])
    const pickupLocationOptions = ref([])
    const additionalFeesInfoOptions = ref([])
    const customerTypeOptions = ref([])
    const brokerOptions = ref([])
    const corporateOptions = ref([])
    const loyaltyOptions = ref([])
    const motorInsuranceId = ref(null)
    const otherPickupLocationId = ref(null)
    const otherAdditionalFeesInfoId = ref(null)
    const loading = ref(false)
    const searchLoading = ref(false)
    const fillLoading = ref(false)
    const searchText = ref(null)
    const isExpiryDateMenuOpen = ref(false)
    const isAdditionalDateMenuOpen = ref(false)
    const invoiceMetaList = ref(null)
    const netPaymentRef = ref(null)
    const issueDate = ref(null)
    const voucher = ref({
      code: null,
      amount: null,
    })
    const voucherIssueAmount = ref(null)
    const voucherAmountOptions = ref([
      { id: null, name: 'No voucher' },
      { id: 5, name: '$5' },
      { id: 10, name: '$10' },
      { id: 20, name: '$20' },
    ])
    const uploadLoading = ref(false)
    const uploadScreenshotLoading = ref(false)
    const policyUploadRules = [value => !value || value.size < 5000000 || 'Policy file has to be lower than 5MB']
    const paymentScreenshotRules = [value => !value || value.size < 5000000 || 'Bank payment image has to be lower than 5MB']
    const commissionCustomerType = ref(null)
    const commissionReference = {
      Quoted: [
        { range: '$9-$29', commission: '$1' },
        { range: '$30-$49', commission: '$2' },
        { range: '$50-$99', commission: '$5' },
        { range: '$100 and above', commission: '$10' },
      ],
      'Non quoted/Broker': [
        { range: '$9 and below', commission: '$0' },
        { range: '$10-$29', commission: '$1' },
        { range: '$30-$49', commission: '$2' },
        { range: '$50-$99', commission: '$5' },
        { range: '$100 and above', commission: '$10' },
      ],
      Corporate: [
        { range: '$2-$9', commission: '$0' },
        { range: '$10-$29', commission: '$1' },
        { range: '$30-$49', commission: '$2' },
        { range: '$50-$99', commission: '$5' },
        { range: '$100 and above', commission: '$10' },
      ],
      'Niagamas loyalty': [
        { range: '$4 and below', commission: '$0' },
        { range: '$5-$29', commission: '$1' },
        { range: '$30-$49', commission: '$2' },
        { range: '$50-$99', commission: '$5' },
        { range: '$100 and above', commission: '$10' },
      ],
      'Staff rate': [
        { range: '-', commission: '$0' },
      ],
    }

    // Utils
    const formatDate = date => {
      if (!date) return null
      if (date.includes('/')) return date
      const [year, month] = date.split('-')

      return `${month}/${year}`
    }
    const checkNetPayment = (netPayment, afterDiscount) => {
      if (netPayment === null || afterDiscount === null) return false

      return Number(netPayment.replace(/[^0-9.-]+/g, '')) <= Number(afterDiscount.replace(/[^0-9.-]+/g, ''))
    }
    const checkProfit = () => {
      if (receipt.value.customer_type !== 'Quoted' || receipt.value.insurance_category_id !== 1) return true
      const afterDiscount = parseFloat(receipt.value.after_discount) || 0.00
      const voucherAmount = parseFloat(voucher.value.amount) || 0.00
      const finalAfterDiscount = parseFloat(Math.max(0, afterDiscount - voucherAmount))
      const netPayment = parseFloat(receipt.value.net_payment) || 0.00
      const profit = finalAfterDiscount - netPayment

      return profit >= 9
    }
    const netPaymentRules = [
      () => checkNetPayment(receipt.value.net_payment, receipt.value.after_discount) || 'Must be lower than after discount',
      () => checkProfit() || 'Profit for Quoted customer must be more than or equal to $9. Please adjust net payment',
    ]

    // Computed
    const expiryDateFormatted = computed(() => formatDate(receipt.value.insurance_expired_at))

    // Methods
    const fetchInvoiceMetas = query => {
      if (!query || (query && query.includes('-'))) return

      searchLoading.value = true
      store
        .dispatch('app-receipt/fetchInvoiceMetas', {
          search_text: query,
        })
        .then(response => {
          searchLoading.value = false
          const { data } = response.data
          invoiceMetas.value = data
        })
        .catch(error => {
          searchLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching invoice metas. Please refresh!')
        })
    }
    const parseCurrency = value => (value === null ? null : value.replace(/[$,]/g, ''))
    const fillWithInvoice = () => {
      fillLoading.value = true
      store
        .dispatch('app-invoice/fetchInvoice', { id: receipt.value.invoice_id })
        .then(response => {
          fillLoading.value = false
          const invoice = response.data.data

          receipt.value.branch_id = invoice.branch.id
          receipt.value.customer_name = invoice.customer_name
          receipt.value.customer_contact_number = invoice.customer_contact_number
          if (invoice.insurance_category) receipt.value.insurance_category_id = invoice.insurance_category.id
          receipt.value.insurance_product_id = invoice.insurance_product.id
          receipt.value.car_registration_number = invoice.car_registration_number
          receipt.value.insurance_id = invoice.insurance.id
          receipt.value.pickup_location_id = invoice.pickup_location ? invoice.pickup_location.id : null
          receipt.value.sum_insured = parseCurrency(invoice.sum_insured)
          receipt.value.insurance_premium = parseCurrency(invoice.insurance_premium)
          receipt.value.after_discount = parseCurrency(invoice.after_discount)
          receipt.value.road_tax = parseCurrency(invoice.road_tax)
          receipt.value.additional_fees = parseCurrency(invoice.additional_fees)
          receipt.value.full_spd_number = invoice.spd_number
          receipt.value.remarks.general = invoice.general_remarks
          receipt.value.remarks.pickup_location = invoice.pickup_location_remarks
          issueDate.value = invoice.created_at
        })
        .catch(error => {
          fillLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while filling receipt. Please refresh!')
        })
    }
    const fetchPaymentMethods = () => {
      store
        .dispatch('app-receipt/fetchPaymentMethods')
        .then(response => {
          const { data } = response.data
          paymentMethodOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching payment methods. Please refresh!')
        })
    }
    const fetchInsuranceCategories = () => {
      store
        .dispatch('app-receipt/fetchInsuranceCategories')
        .then(response => {
          const { data } = response.data
          insuranceCategoryOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance categories. Please refresh!')
        })
    }
    const fetchInsuranceProducts = () => {
      store
        .dispatch('app-receipt/fetchInsuranceProducts')
        .then(response => {
          const { data } = response.data
          insuranceProductOptions.value = data
          motorInsuranceId.value = insuranceProductOptions.value.filter(product => product.name === 'Motor insurance')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurance products. Please refresh!')
        })
    }
    const fetchInsurances = () => {
      store
        .dispatch('app-receipt/fetchInsurances')
        .then(response => {
          const { data } = response.data
          insuranceOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching insurances. Please refresh!')
        })
    }
    const fetchPickupLocations = () => {
      store
        .dispatch('app-receipt/fetchPickupLocations')
        .then(response => {
          const { data } = response.data
          pickupLocationOptions.value = data
          otherPickupLocationId.value = pickupLocationOptions.value.filter(pickup => pickup.name === 'Others')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching pickup location. Please refresh!')
        })
    }
    const fetchAdditionalFeesInfos = () => {
      store
        .dispatch('app-receipt/fetchAdditionalFeesInfos')
        .then(response => {
          const { data } = response.data
          additionalFeesInfoOptions.value = data
          otherAdditionalFeesInfoId.value = additionalFeesInfoOptions.value.filter(info => info.name === 'Others')[0].id
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching additional fees info. Please refresh!')
        })
    }
    const fetchCustomerTypes = () => {
      store
        .dispatch('app-receipt/fetchCustomerTypes')
        .then(response => {
          const { data } = response.data
          customerTypeOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching customer types. Please refresh!')
        })
    }
    const fetchBrokers = () => {
      store
        .dispatch('app-receipt/fetchBrokers')
        .then(response => {
          const { data } = response.data
          brokerOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching brokers. Please refresh!')
        })
    }
    const fetchCorporates = () => {
      store
        .dispatch('app-receipt/fetchCorporates')
        .then(response => {
          const { data } = response.data
          corporateOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching corporates. Please refresh!')
        })
    }
    const fetchLoyalties = () => {
      store
        .dispatch('app-receipt/fetchLoyalties')
        .then(response => {
          const { data } = response.data
          loyaltyOptions.value = data
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching niagamas loyalties. Please refresh!')
        })
    }
    const updateReceiptVoucher = receiptId => {
      receipt.value.voucher_code = null
      receipt.value.voucher_amount = null
      store
        .dispatch('app-receipt/updateReceipt', { id: receiptId, receipt: receipt.value })
        .then(response => {
          const { data } = response.data
          router.push(`/apps/receipts/preview/${data.id}`)
        })
        .catch(() => {
          router.push(`/apps/receipts/preview/${receiptId}`)
          snackbarService.error('Something went wrong while updating voucher & receipt. Please contact admin!')
        })
    }
    const updateVoucher = receiptId => {
      voucher.value.used_at = new Date()
      voucher.value.used_by = userData.nickname
      voucher.value.receipt_url = `${window.location.origin}/apps/receipts/preview/${receiptId}`
      store
        .dispatch('voucherStore/updateVoucher', voucher.value)
        .then(() => {
          router.push(`/apps/receipts/preview/${receiptId}`)
        })
        .catch(() => {
          updateReceiptVoucher(receiptId)
        })
    }
    const createReceipt = () => {
      loading.value = true
      errors.value = []
      if (receipt.value.insurance_expired_at) {
        receipt.value.insurance_expired_at = `${receipt.value.insurance_expired_at}-01`
      }
      store
        .dispatch('app-receipt/createReceipt', { receipt: receipt.value, voucher_issued_amount: voucherIssueAmount.value })
        .then(response => {
          const { data } = response.data
          if (voucher.value.id) {
            updateVoucher(data.id)
          } else {
            router.push(`/apps/receipts/preview/${data.id}`)
          }
          loading.value = false
        })
        .catch(error => {
          loading.value = false
          errors.value = error.response.data.errors
          snackbarService.error(error.response.data.message || 'Something went wrong while saving receipt. Please refresh!')
        })
    }
    const checkVoucher = () => {
      store
        .dispatch('voucherStore/checkVoucher', { code: voucher.value.code })
        .then(response => {
          voucher.value = response.data.data
          receipt.value.voucher_code = voucher.value.code
          receipt.value.voucher_amount = voucher.value.amount
          snackbarService.success(response.data.message)
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching voucher. Please refresh!')
        })
    }
    const clearVoucherUse = () => {
      if (voucher.value.id === null) return
      voucher.value.id = null
      voucher.value.amount = ''
      receipt.value.voucher_code = null
      receipt.value.voucher_amount = null
    }
    const clearPaymentDetailNumbers = () => {
      receipt.value.trace_number = null
      receipt.value.partial_card_number = null
    }
    const clearRoadTaxInfo = () => {
      receipt.value.car_registration_number = null
      receipt.value.road_tax = null
      receipt.value.require_inspection = null
      receipt.value.crw_number = null
      receipt.value.require_additional_process_time = null
      receipt.value.processing_date = null
      receipt.value.express_charges = null
      receipt.value.delivery_charges = null
      receipt.value.remarks.address = null
      receipt.value.pickup_location_id = null
      receipt.value.remarks.pickup_location = null
    }
    const clearDeliveryAddress = () => {
      receipt.value.remarks.address = null
    }
    const isNumber = event => {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const keyPressed = event.key
      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault()
      }
    }
    const directUploadPolicy = file => {
      if (file == null) {
        receipt.value.insurance_policy = null
        receipt.value.insurance_policy_name = null

        return
      }

      uploadLoading.value = true
      store
        .dispatch('app-receipt/directUploadPolicy', file)
        .then(response => {
          uploadLoading.value = false
          if (response) {
            receipt.value.insurance_policy = response.signed_id
            receipt.value.insurance_policy_name = response.filename
          }
        })
        .catch(error => {
          uploadLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while uploading policy. Please refresh!')
        })
    }
    const directUploadScreenshot = file => {
      if (file == null) {
        receipt.value.payment_screenshot = null
        receipt.value.payment_screenshot_name = null

        return
      }

      uploadScreenshotLoading.value = true
      store
        .dispatch('app-receipt/directUploadScreenshot', file)
        .then(response => {
          uploadScreenshotLoading.value = false
          if (response) {
            receipt.value.payment_screenshot = response.signed_id
            receipt.value.payment_screenshot_name = response.filename
          }
        })
        .catch(error => {
          uploadScreenshotLoading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while uploading screenshot. Please refresh!')
        })
    }
    const updateProcessingDate = event => {
      receipt.value.processing_date = !event ? null : new Date().toISOString().substring(0, 10)
    }
    const customerTypeChange = () => {
      commissionCustomerType.value = receipt.value.customer_type
      receipt.value.broker_id = null
      receipt.value.corporate_id = null
      receipt.value.niagamas_loyalty_id = null
      if (receipt.value.customer_type === 'Non quoted/Broker' || receipt.value.customer_type === 'Corporate' || receipt.value.customer_type === 'Niagamas loyalty') {
        receipt.value.customer_name = null
        receipt.value.customer_contact_number = null
      }
      receipt.value.customer_ic_number = null
      receipt.value.roc_number = null
    }
    const brokerChange = () => {
      const broker = brokerOptions.value.find(b => b.id === receipt.value.broker_id)
      receipt.value.customer_contact_number = broker.contact_number
      receipt.value.customer_ic_number = broker.ic_number
    }
    const corporateChange = () => {
      const corporate = corporateOptions.value.find(c => c.id === receipt.value.corporate_id)
      receipt.value.customer_contact_number = corporate.contact_person_number
      receipt.value.roc_number = corporate.roc_number
    }
    const loyaltyChange = () => {
      const loyalty = loyaltyOptions.value.find(l => l.id === receipt.value.niagamas_loyalty_id)
      receipt.value.customer_contact_number = loyalty.contact_number
      receipt.value.customer_ic_number = loyalty.ic_number
    }

    // Computed
    const branchOptions = computed(() => store.state.branches.filter(b => b.active))
    const isMotorInsurance = computed(() => (motorInsuranceId.value === receipt.value.insurance_product_id))
    const isOtherPickupLocation = computed(() => {
      if (otherPickupLocationId.value !== receipt.value.pickup_location_id) receipt.value.remarks.pickup_location = null

      return otherPickupLocationId.value === receipt.value.pickup_location_id
    })
    const isOtherAdditionalFeesInfo = computed(() => {
      if (otherAdditionalFeesInfoId.value !== receipt.value.additional_fees_info_id) receipt.value.remarks.additional_fees = null

      return otherAdditionalFeesInfoId.value === receipt.value.additional_fees_info_id
    })
    const calculatedTotal = computed(() => {
      const afterDiscount = parseFloat(receipt.value.after_discount) || 0.00
      const roadTax = parseFloat(receipt.value.road_tax) || 0.00
      const additionalFees = parseFloat(receipt.value.additional_fees) || 0.00
      const expressCharges = parseFloat(receipt.value.express_charges) || 0.00
      const deliveryCharges = parseFloat(receipt.value.delivery_charges) || 0.00
      const voucherAmount = parseFloat(voucher.value.amount) || 0.00
      const finalAfterDiscount = parseFloat(Math.max(0, afterDiscount - voucherAmount))
      const sumTotal = finalAfterDiscount + roadTax + additionalFees + expressCharges + deliveryCharges

      return parseFloat(sumTotal).toFixed(2)
    })
    const commissionReferenceData = computed(() => commissionReference[commissionCustomerType.value])

    // Mounted
    onMounted(() => {
      fetchPaymentMethods()
      fetchInsuranceCategories()
      fetchInsuranceProducts()
      fetchInsurances()
      fetchPickupLocations()
      fetchAdditionalFeesInfos()
      fetchCustomerTypes()
      fetchBrokers()
      fetchCorporates()
      fetchLoyalties()
    })

    // Watch
    let timer = null
    watch(searchText, query => {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      timer = setTimeout(() => {
        invoiceMetaList.value.cachedItems = []
        fetchInvoiceMetas(query)
      }, 200)
    })

    // Unmounted
    onUnmounted(() => {
      if (store.hasModule(RECEIPT_APP_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_APP_STORE_MODULE_NAME)
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    return {
      t,
      receipt,
      errors,
      createReceipt,
      userData,
      dateToday,
      branchOptions,
      invoiceMetas,
      paymentMethodOptions,
      insuranceCategoryOptions,
      insuranceProductOptions,
      insuranceOptions,
      pickupLocationOptions,
      additionalFeesInfoOptions,
      customerTypeOptions,
      brokerOptions,
      corporateOptions,
      loyaltyOptions,
      isMotorInsurance,
      isOtherPickupLocation,
      isOtherAdditionalFeesInfo,
      calculatedTotal,
      loading,
      searchLoading,
      fillLoading,
      fetchInvoiceMetas,
      fillWithInvoice,
      issueDate,
      searchText,
      isExpiryDateMenuOpen,
      isAdditionalDateMenuOpen,
      invoiceMetaList,
      checkNetPayment,
      expiryDateFormatted,
      netPaymentRef,
      clearPaymentDetailNumbers,
      clearRoadTaxInfo,
      clearDeliveryAddress,
      voucher,
      voucherIssueAmount,
      voucherAmountOptions,
      checkVoucher,
      clearVoucherUse,
      isNumber,
      policyUploadRules,
      paymentScreenshotRules,
      netPaymentRules,
      uploadLoading,
      uploadScreenshotLoading,
      directUploadPolicy,
      directUploadScreenshot,
      updateProcessingDate,
      commissionCustomerType,
      commissionReference,
      commissionReferenceData,
      customerTypeChange,
      brokerChange,
      corporateChange,
      loyaltyChange,
      icons: {
        mdiAlertCircleOutline,
        mdiPaperclip,
        mdiCalendar,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/style.scss';

.app-receipt {
  .autofill-button {
    margin-top: 40px;
  }

  .receipt-add-branch {
    width: 15rem;
  }
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 !important;
}
</style>
